/* Variables for consistent theming */
:root {
  --font-family: 'Old Standard TT', serif;
  --code-font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  --background-color: #111;
  --text-color: #fff;
  --primary-color: #00aaff;
  --secondary-color: #ffaa00;
}

/* Global Styles */
body {
  margin: 0;
  font-family: var(--font-family);
  background-color: var(--background-color);
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: var(--code-font-family);
}

/* Logo Section */
#logo-section {
  text-align: center;
  margin: 20px 0;
}

/* Section Styling */
section {
  padding: 40px 20px;
  text-align: center;
}

/* Title Styling */
h2 {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 20px;
}

/* Paragraph Styling */
p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: var(--text-color);
  margin-bottom: 20px;
}

/* Navigation Styling */
nav {
  background-color: var(--background-color);
}

nav ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px 0;
  margin: 0;
}

nav ul li {
  margin: 5px 15px;
}

nav ul li a {
  color: var(--primary-color);
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s ease-in-out;
}

nav ul li a:hover {
  color: var(--secondary-color);
}

/* Contact Section */
.contact-info {
  text-align: center;
  margin: 20px 0;
}

.contact-info i {
  margin-right: 10px;
}

/* Footer Styling */
footer {
  background-color: var(--background-color);
  padding: 20px 0;
  text-align: center;
}

footer a {
  font-size: 1.5rem;
  color: var(--secondary-color);
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

footer a:hover {
  color: var(--primary-color);
}

/* Responsive Design */
@media (min-width: 768px) {
  #logo-section img {
    width: 400px;
  }

  h2 {
    font-size: 2.5rem;
  }

  p {
    font-size: 1.4rem;
  }

  nav ul li a {
    font-size: 1.5rem;
  }

  footer a {
    font-size: 2rem;
  }
}
