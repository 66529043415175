/* Cleaning Services Section */
.services-container {
  background-color: #222;
  padding: 40px;
  color: #f0f0f0;
  text-align: center;
}

.services-container h2 {
  color: #00aaff;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.services-list {
  list-style: none;
  padding: 0;
}

.services-list li {
  background-color: rgba(230, 242, 255, 0.1);
  margin: 10px 0;
  padding: 15px;
  border-radius: 5px;
  font-size: 1.5rem;
  transition: all 0.3s ease;
}

.services-list li:hover {
  background-color: rgba(230, 242, 255, 0.2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
