/* Gallery Container */
.gallery-container {
  padding: 20px;
  text-align: center;
  background-color: #222;
  color: #f0f0f0;
}

.gallery-container h2 {
  color: #00aaff;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.gallery-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.gallery-grid img,
.video-thumbnail {
  width: 150px;
  height: 150px;
  object-fit: cover;
  cursor: pointer;
  position: relative;
  transition: transform 0.3s ease-in-out;
}

.gallery-grid img:hover,
.video-thumbnail:hover video {
  transform: scale(1.05);
}

/* Video Thumbnail */
.video-thumbnail video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.video-thumbnail .play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  color: #fff;
  text-shadow: 0 0 10px rgba(0,0,0,0.5);
  pointer-events: none;
}

/* Lightbox Styling */
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lightbox-content {
  max-width: 90%;
  max-height: 90%;
}

.close-button {
  position: fixed;
  top: 30px;
  right: 30px;
  color: #f0f0f0;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
  z-index: 1001;
}

.close-button:hover {
  color: #bbb;
}
