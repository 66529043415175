/* Reset Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Import App Styles */
@import './App.css';

/* Additional Global Styles */
html, body {
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
