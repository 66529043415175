/* Footer Styling */
.footer {
  background-color: #0066cc;
  color: white;
  padding: 20px;
  text-align: center;
}

.footer p {
  margin: 0;
  font-size: 1rem;
}

.footer a {
  display: block;
  margin-top: 10px;
  font-size: 1.2rem;
  color: #00ccff;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.footer a:hover {
  color: white;
}
