/* Contact Section */
.contact-container {
  text-align: center;
  padding: 40px;
  background-color: #222;
  color: #f0f0f0;
}

.contact-container h2 {
  color: #00aaff;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.contact-list {
  list-style: none;
  padding: 0;
}

.contact-item {
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.contact-icon {
  margin-right: 10px;
  color: #0066cc;
  vertical-align: middle;
}

.contact-container a {
  color: #66ccff;
  text-decoration: none;
}

.contact-container a:hover {
  text-decoration: underline;
}
