header {
  text-align: center;
  padding: 1rem;
  background-color: rgba(0, 115, 230, 0.9);
}

nav ul {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

nav ul li {
  margin: 0 1rem;
}

nav ul li a {
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: #00ff99;
}
